@use '@sparbanken-syd/sparbanken-syd-theme/theme' as spb;
@use '@sparbanken-syd/sparbanken-syd-theme/variables' as var;
@use '@angular/material' as mat;
@include spb.set-typography;

html {
  height: 100%;
  width: 100%;
}

/*
Header in tables
*/
th {
  color: rgb(0 0 0 / 54%) !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

body {
  height: 100%;
  margin: 0 auto;
  width: 100%;
}

.spb-filler {
  flex-grow: 1;
}

.spb-error {
  background-color: var.$spb-red;
  color: #fff;
  font-weight: bold;
  margin: 1em auto;
  padding: 0.7em;
  text-align: center;
  width: 90%;
}

a {
  color: #232323;
}

